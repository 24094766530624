import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Button from "../components/button";

import SEO from "../components/seo";
import BlogPostItem from "../components/blog-post-item";

import Breadcrumbs from "../components/breadcrumbs";

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
`;

const Heading = styled.div`
  background: #fff;

  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`;

const BlogCategoryPage = (props) => {
  let { data } = props;
  let { pageContext } = props;
  let categoryTitle = pageContext.name;
  let blogPostsCount = data.contentfulBlogCategory.blog_post && data.contentfulBlogCategory.blog_post.length;

  return (
    <Layout pageProps={props}>
      <div className="bg-white border-b border-gray-100">
        <nav className="flex container mx-auto px-4 py-3" aria-label="Breadcrumb">
          <Breadcrumbs
            links={[
              { to: "/", label: "Home" },
              { to: "/blog/", label: "Blog" },
              {
                to: props.location.pathname,
                label: categoryTitle,
              },
            ]}
          />
        </nav>
      </div>
      <SEO
        title={categoryTitle + " - West Coast Hydronics & Plumbing Blog "}
        description={props.pageContext.description}
        robots={"noindex"}
      />
      <Heading>
        <h1>{categoryTitle} Blog</h1>
        <p>
          {blogPostsCount} post{blogPostsCount > 1 ? "s" : ""}
        </p>
      </Heading>
      <BlogPostsLayoutContainer className="container">
        {data.contentfulBlogCategory &&
          data.contentfulBlogCategory.blog_post &&
          data.contentfulBlogCategory.blog_post.map((item, i) => (
            <BlogPostItem
              key={i}
              title={item.title}
              slug={item.slug}
              image={item.heroImage}
              publishDate={item.publishDate}
              categorySlug={item.category.slug}
              category={item.category.name}
              author={item.author.name}
              excerpt={item.metaDescription}
            />
          ))}
      </BlogPostsLayoutContainer>
    </Layout>
  );
};

export default BlogCategoryPage;

export const pageQuery = graphql`
  query GetBlogPostsByCategory($slug: String!) {
    contentfulBlogCategory(slug: { eq: $slug }) {
      id
      name
      blog_post {
        id
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
        }
        metaDescription
        author {
          name
        }
        category {
          slug
          name
          description
        }
      }
    }
  }
`;
